
    /* .slick-prev,
    .slick-next {
      display: none !important;
    } */

    .slick-prev:before, .slick-next:before{
      font-size: 38px;
      z-index: 50;
    }
    .slick-dots {
      bottom: -80px;
    }

    .slick-dots li {
      margin: 0 5px;
    }

    .slick-dots li button {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: red;
      border: 2px solid #fff;
      opacity: 0.6;
    }

    .slick-dots li.slick-active button {
      background-color: #fff;
      opacity: 1;
    }
    .slick-dots li button:before {
      content: "";
    }

    @media(max-width:500px){
      .slick-dots{
        display: none !important;
      }
      .slick-next:before , .slick-prev:before {
        font-size: 20px;
      }
    }
  