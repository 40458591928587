
@tailwind base;
*{
    margin: 0;
    padding: 0;
}
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap') 